import React from "react"
import {graphql} from "gatsby"
import SEO from "../components/seo";
import Layout from "../components/layout";
import {Link} from 'gatsby'
import Img from "gatsby-image"

export default function Blog({
                                 data, // this prop will be injected by the GraphQL query below.
                             }) {

    const {allMarkdownRemark} = data;

    return (
        <Layout>
            <SEO
                keywords={[`domain`, `name`, `generator`, `tool`]}
                title="Domainprinter - Blog"
            />
            <div className="flex-1 w-full py-4 mx-auto">
                <div className="sm:px-6 px-5 pb-16 dark:bg-gray-800 lg:max-w-4xl mx-auto">
                    <div className="text-center pb-8">
                        <h1 className="font-bold text-4xl">Blog</h1>
                        <p>Guides and tips to help you with domains</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3">
                        {allMarkdownRemark.nodes.map(it => {
                            return (

                                    <Link to={it.frontmatter.slug} className="flex flex-col border-black p-3 blog-grid-item">
                                        <Img className="blog-image" fluid={it.frontmatter.image.childImageSharp.fluid} alt="" />
                                        <div>
                                            <h2 className="pb-0">{it.frontmatter.title}</h2>
                                        </div>
                                        <div className="mb-2">
                                            {it.frontmatter.meta}
                                        </div>
                                    </Link>

                            )
                        })}
                    </div>
                </div>
            </div>
            <section>
                <div className="sm:px-6 px-5 pb-16 dark:bg-gray-800 lg:max-w-4xl mx-auto">

                </div>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query {
        allMarkdownRemark {
    nodes {
      html
      frontmatter {
        title
        slug
        meta
        image {
            id
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
            }
          }
        date
      }
    }
  }
}
`